import { gql } from '../../../__generated__';

export const GET_MONTHLY_AVAILABILITY =
  gql(`query MonthlyAvailability($filter: GetMonthlyAvailabilityFilter!) {
  monthlyAvailability(filter: $filter) {
    data {
      date
      day
      timeSlots
    }
    count
    eventType {
      id
      duration
      buffer
      type
      upcomingSessionDates
      sessionStartDate
      dateRangeType
    }
  }
}`);

export const GET_USER_MONTHLY_BOOKINGS =
  gql(`query MonthlyBookings($filter: MonthlyBookingsFiler!) {
  monthlyBookings(filter: $filter) {
    count
    data {
     startTime
      endTime
      status
    }
  }
}`);

export const GET_USER_UNAVAILABILITY =
  gql(`query Availability($filter: GetAvailabilityFilter!) {
  availability(filter: $filter) {
    data {
      startDate
      endDate
      isUnavailability
      availabilityDetails
    }
  }
}`);

export const GET_EVENT_UNAVAILABILITY =
  gql(`query EventUnAvailabilities($filter: GetAvailabilityFilter!) {
  eventUnavailabilities(filter: $filter) {
    data {
      startDate
      endDate
      isUnavailability
      availabilityDetails
    }
  }
}
`);
